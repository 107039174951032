import {
  beforeEachProviders,
  describe,
  expect,
  it,
  inject
} from '@angular/core/testing';
import { BlazeAppAppComponent } from '../app/blaze-app.component';

beforeEachProviders(() => [BlazeAppAppComponent]);

describe('App: BlazeApp', () => {
  it('should create the app',
      inject([BlazeAppAppComponent], (app: BlazeAppAppComponent) => {
    expect(app).toBeTruthy();
  }));

  it('should have as title \'blaze-app works!\'',
      inject([BlazeAppAppComponent], (app: BlazeAppAppComponent) => {
    expect(app.title).toEqual('blaze-app works!');
  }));
});
